export const Categories = [
  { name: "Новости" },
  { name: "Тренды" },
  { name: "Политика" },
  { name: "Бизнес" },
  { name: "Наука" },
  { name: "Культура" },
  { name: "Фильмы" },
  { name: "Сериалы" },
  { name: "Игры" },
  { name: "Технологии" },
  { name: "Спорт" },
]
